import { Tag } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  label: ReactNode;
  isOptional?: boolean;
  hasError?: boolean;
  hasMissingRequiredError?: boolean;
  font?: string;
  margin?: string | 0;
  withFlexDirectionColumn?: boolean;
}

const FormLabel = ({
  label,
  isOptional,
  hasError,
  hasMissingRequiredError,
  font = FONTS.SEMIBOLD_2,
  margin,
  withFlexDirectionColumn,
}: Props) => (
  <Container margin={margin} withFlexDirectionColumn={withFlexDirectionColumn}>
    <LabelText font={font} hasError={hasError}>
      {label}
    </LabelText>
    {isOptional ? (
      <Tag
        content="Optional"
        backgroundColor={COLORS.ORANGE_100}
        color={COLORS.ORANGE}
        font={FONTS.SEMIBOLD_3}
        margin={withFlexDirectionColumn ? "8px 0 0" : "0 0 0 16px"}
      />
    ) : (
      hasMissingRequiredError && <Required>Required</Required>
    )}
  </Container>
);

export default FormLabel;

const Container = styled.div`
  display: flex;
  ${({ withFlexDirectionColumn }) =>
    withFlexDirectionColumn ? "flex-direction: column" : "align-items: baseline"};
  ${({ margin }) => margin && `margin: ${margin}`};
`;
const LabelText = styled.span`
  ${({ font }) => font};
  color: ${({ hasError }) => (hasError ? COLORS.RED_500 : COLORS.BLACK)};
`;
const Required = styled.span`
  margin-left: 16px;
  ${FONTS.MEDIUM_2};
  color: ${COLORS.RED_500};
`;
