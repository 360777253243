import IconButton from "@components/library/Buttons/IconButton";
import MultiSelectDropdown from "@components/library/Dropdowns/MultiSelectDropdown";
import { COLORS, FONTS } from "@constants";
import {
  createKeyword,
  getAreasOfExpertise,
  getDisciplines,
  getKeywordSuggestionsBySimilarity,
} from "@requests/keywords";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { KeywordType } from "@tsTypes/keywords";
import { track } from "@utils/appUtils";
import { getOptionFromValue, getValueFromOption } from "@utils/dropdownUtils";
import { t } from "@utils/i18n";
import debounce from "debounce-promise";
import Fuse from "fuse.js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ActionMeta } from "react-select";
import styled from "styled-components";
import type { DropdownOption, DropdownOptionOrGroup } from "../DropdownOption";
import KeywordsDropdownCreateLabel from "./KeywordsDropdownCreateLabel";
import KeywordsDropdownOption from "./KeywordsDropdownOption";

type SuggestedDropdownOption = DropdownOption & { suggested?: boolean };

const KEYWORD_TYPE_TO_CLASS = {
  discipline: "Discipline",
  area_of_expertise: "AreaOfExpertise",
} as const;

const ANALYTICS_UI_COMPONENT = "keywords_dropdown";
enum SuggestionType {
  INPUT_SUGGESTION = "input_suggestion", // AI-suggested keywords based on input
  VALUE_SUGGESTION = "value_suggestion", // AI-suggested keywords based on selected values
  CREATED = "created", // User-created keyword
  NONE = "none", // From normal dropdown options
}

const MAX_DISCIPLINES = 3;
const MAX_AREAS_OF_EXPERTISE = 10;
// Keyword minimums only apply to scientists and requests
const MIN_DISCIPLINES = 1;
export const MIN_AREAS_OF_EXPERTISE = 3;

interface Props {
  keywordType: KeywordType;
  targetType:
    | "scientist"
    | "sponsor"
    | "university_admin"
    | "request"
    | "company_research_interest";
  currentUserKeywords?: {
    disciplines?: string[];
    areas_of_expertise?: string[];
  };
  value: string[];
  onChange: (selection: string[]) => void;
  label?: string;
  labelFont?: string;
  instructionText?: string;
  placeholder?: string;
  // NOTE: withSuggestions and autoPopulateFn are mutually exclusive, in order to not confuse/overwhelm users with both features
  withSuggestions?: boolean;
  autoPopulateFn?: () => void;
  menuPlacement?: "top" | "auto" | "bottom";
  isPortal?: boolean;
  maxMenuHeight?: string;
  maxValueContainerHeight?: string;
  helpText?: string;
  errors?: { hasError: boolean; errorMessage: string }[];
  isDisabled?: boolean;
}

const KeywordsDropdown = ({
  keywordType,
  targetType,
  currentUserKeywords,
  value,
  onChange,
  label,
  labelFont,
  instructionText,
  placeholder,
  withSuggestions = true,
  autoPopulateFn,
  menuPlacement,
  isPortal = false,
  maxMenuHeight,
  maxValueContainerHeight = "unset",
  helpText,
  errors,
  isDisabled = false,
}: Props) => {
  const [allKeywords, setAllKeywords] = useState<string[]>([]);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [defaultOptions, setDefaultOptions] = useState<DropdownOptionOrGroup[]>([]);
  const [options, setOptions] = useState<DropdownOptionOrGroup[]>([]);
  const [isInputSuggestionsLoading, setIsInputSuggestionsLoading] = useState(false);

  // Grab set of raw keywords
  useEffect(() => {
    (async () => {
      let keywords: string[] = [];
      switch (keywordType) {
        case "discipline":
          keywords = await getDisciplines();
          break;
        case "area_of_expertise":
          keywords = await getAreasOfExpertise();
          break;
      }
      setAllKeywords(keywords);

      let _options: DropdownOptionOrGroup[] = [];
      const usedKeywords: string[] = [];
      if (currentUserKeywords?.disciplines?.length) {
        _options.push({
          label: "Your disciplines",
          options: currentUserKeywords.disciplines.map(getOptionFromValue) as DropdownOption[],
        });
        usedKeywords.push(...currentUserKeywords.disciplines);
      }
      if (currentUserKeywords?.areas_of_expertise?.length) {
        _options.push({
          label: "Your areas of expertise",
          options: currentUserKeywords.areas_of_expertise.map(
            getOptionFromValue
          ) as DropdownOption[],
        });
        usedKeywords.push(...currentUserKeywords.areas_of_expertise);
      }

      // Add normal keywords excluding extra groups
      if (usedKeywords.length) {
        _options.push({
          label: "All options",
          options: keywords
            .filter((keyword) => !usedKeywords.includes(keyword))
            .map(getOptionFromValue) as DropdownOption[],
        });
      } else {
        // Add options without group label if no extra groups were created
        _options = keywords.map(getOptionFromValue) as DropdownOption[];
      }
      setDefaultOptions(_options);
      setOptions(_options);
    })();
  }, []);

  // Suggestions
  const debouncedGetKeywordSuggestionsForValue = useCallback(
    debounce(getKeywordSuggestionsBySimilarity, 500),
    []
  );
  const debouncedGetKeywordSuggestionsForInput = useCallback(
    debounce(getKeywordSuggestionsBySimilarity, 500),
    []
  );
  const getSuggestionsForValue = async (values: string[], withDebounce = true) => {
    if (!withSuggestions) return;

    if (values.length === 0) {
      setSuggestions([]);
      return;
    }
    setSuggestions(
      withDebounce
        ? await debouncedGetKeywordSuggestionsForValue(values, KEYWORD_TYPE_TO_CLASS[keywordType])
        : await getKeywordSuggestionsBySimilarity(values, KEYWORD_TYPE_TO_CLASS[keywordType])
    );
  };
  const getSuggestionsForInput = async (input: string): Promise<string[]> => {
    if (input.length === 0 || !withSuggestions) {
      return [];
    }
    return debouncedGetKeywordSuggestionsForInput([input], KEYWORD_TYPE_TO_CLASS[keywordType]);
  };

  // Format options into groups
  const formatOptionsWithSuggestions = (keywords: string[], querySuggestions: string[]) => {
    const _options: DropdownOptionOrGroup[] = keywords.map(getOptionFromValue) as DropdownOption[];
    if (querySuggestions.length > 0) {
      _options.push({
        hasDivider: keywords.length > 0,
        iconName: "Magic",
        label: "Alternative terms",
        options: querySuggestions
          .filter((keyword) => !keywords.includes(keyword) && !value.includes(keyword))
          .map((v) => ({
            ...getOptionFromValue(v),
            suggested: true,
          })) as SuggestedDropdownOption[],
      });
    }
    return _options;
  };

  // Filtering - fuzzy match + input suggestions
  // We're manually controlling options to allow for async input suggestions
  const fuse = useMemo(() => new Fuse(allKeywords, { threshold: 0.4 }), [allKeywords]);
  const handleInputChange = (input: string): void => {
    if (input.length === 0) {
      setOptions(defaultOptions);
      return;
    }

    // Immediately populate fuzzy match filtered options
    const result = fuse.search(input);

    const keywords = result.map((r: any) => r.item).slice(0, 50);
    setOptions(formatOptionsWithSuggestions(keywords, []));

    if (keywords.length > 10) return;

    // Fetch suggestions (async and debounced) and populate at bottom later
    if (keywords.length === 0) setIsInputSuggestionsLoading(true);
    getSuggestionsForInput(input).then((inputSuggestions: string[]) => {
      setOptions(formatOptionsWithSuggestions(keywords, inputSuggestions));
      setIsInputSuggestionsLoading(false);
    });
  };

  const handleChange = (e: SuggestedDropdownOption[], actionMeta?: ActionMeta<DropdownOption>) => {
    const newValues = e.map(getValueFromOption) as string[];

    if (withSuggestions) getSuggestionsForValue(newValues);

    onChange(newValues);

    if (actionMeta?.action === "select-option") {
      track(SegmentEventName.Click, {
        ui_component: ANALYTICS_UI_COMPONENT,
        suggestion_type: e.at(-1)!.suggested ? "input_suggestion" : "none",
        keyword: e.at(-1)!.value,
      });
    }
  };

  const handleCreateOption = async (input: string) => {
    await createKeyword(input, KEYWORD_TYPE_TO_CLASS[keywordType]);
    const newValues = [...value, input];
    getSuggestionsForValue(newValues);
    onChange(newValues);

    track(SegmentEventName.Click, {
      ui_component: ANALYTICS_UI_COMPONENT,
      suggestion_type: SuggestionType.CREATED,
      keyword: input,
    });
  };

  const testid = `${keywordType}-dropdown`;

  let minValues = 0;
  if (["scientist", "request"].includes(targetType)) {
    minValues = keywordType === "discipline" ? MIN_DISCIPLINES : MIN_AREAS_OF_EXPERTISE;
  }
  const maxValues = keywordType === "discipline" ? MAX_DISCIPLINES : MAX_AREAS_OF_EXPERTISE;

  const labelEl = (
    <>
      {label ?? ""}
      {Boolean(instructionText ?? (minValues || maxValues)) && (
        <InstructionText>
          {instructionText ?? ""}
          {`${instructionText ? " " : ""}${(() => {
            if (minValues && maxValues) {
              return t("components.keywords_dropdown.instructions.min_and_max", {
                min: minValues,
                max: maxValues,
              });
            }
            if (minValues)
              return t("components.keywords_dropdown.instructions.min", { min: minValues });
            if (maxValues)
              return t("components.keywords_dropdown.instructions.max", { max: maxValues });
          })()}`}
        </InstructionText>
      )}
    </>
  );

  const placeholderText =
    placeholder ?? t(["components.keywords_dropdown.placeholder", keywordType]);

  return (
    <div>
      <MultiSelectDropdown
        isPortal={isPortal}
        maxMenuHeight={maxMenuHeight}
        maxValueContainerHeight={maxValueContainerHeight}
        maxValues={maxValues}
        menuPlacement={menuPlacement}
        components={{ Option: KeywordsDropdownOption }}
        indicatorButton={
          autoPopulateFn ? (
            <IconButton
              iconName="Magic Refresh"
              tooltipText="Refresh"
              variant="magic"
              size="xs"
              onClick={() => {
                autoPopulateFn?.();

                track(SegmentEventName.Click, {
                  ui_component: "Auto-Populate Refresh Button",
                  react_component: "KeywordsDropdown",
                  keyword_type: keywordType,
                  target_type: targetType,
                });
              }}
            />
          ) : undefined
        }
        formatCreateLabel={KeywordsDropdownCreateLabel}
        onChange={handleChange}
        options={options}
        label={labelEl}
        labelFont={labelFont}
        placeholder={placeholderText}
        value={value.map((text) => ({ value: text, label: text }))}
        helpText={helpText}
        errors={errors}
        onInputChange={handleInputChange}
        filterOption={() => true} // Allow all options to pass filter since we're manually controlling them
        isLoading={isInputSuggestionsLoading}
        isCreatable
        onCreateOption={handleCreateOption}
        withSearchIcon
        withChevronIcon={false}
        data-testid={testid}
        isDisabled={isDisabled}
      />
      {withSuggestions &&
        (suggestions ?? []).length > 0 &&
        value.length > 0 &&
        (!maxValues || value.length < maxValues) && (
          <SuggestionsContainer>
            <div>
              Suggested:{" "}
              {suggestions.map((suggestion, index) => (
                <span key={suggestion}>
                  {index > 0 && ", "}
                  <Suggestion
                    onClick={() => {
                      setSuggestions(suggestions.filter((s) => s !== suggestion));
                      onChange([...value, suggestion]);
                      track(SegmentEventName.Click, {
                        ui_component: ANALYTICS_UI_COMPONENT,
                        suggestion_type: SuggestionType.VALUE_SUGGESTION,
                        keyword: suggestion,
                      });
                    }}
                  >
                    {suggestion}
                  </Suggestion>
                </span>
              ))}
            </div>
            <IconButton
              iconName="Magic Refresh"
              tooltipText="Refresh"
              variant="fourth"
              onClick={() => {
                getSuggestionsForValue(value, false);
                track(SegmentEventName.Click, {
                  ui_component: "Refresh Button",
                  react_component: "KeywordsDropdown",
                  num_remaining_suggestions: suggestions.length,
                });
              }}
              margin="0 0 0 auto"
            />
          </SuggestionsContainer>
        )}
    </div>
  );
};

export default KeywordsDropdown;

const InstructionText = styled.div`
  ${FONTS.REGULAR_2};
  color: ${COLORS.BLACK};
`;
const SuggestionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  margin-top: 10px;
  height: 36px;
  ${FONTS.REGULAR_3};
`;
const Suggestion = styled.button`
  padding: 0;
  text-decoration: underline;
  color: ${COLORS.BLACK};
  border: none;
  background: none;
`;
