import { tabbed_marketplace_path } from "@routes/routes";

export const formatPathForRouter = (url) => {
  return url.toString().replace("(.:format)", "");
};

// Helper to manage Flipper-conditional marketplace route
export const marketplacePath = (tab = "requests", options = {}) => {
  return tabbed_marketplace_path(tab, options);
};
