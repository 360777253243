/**
 * USAGE:
 * import toast from '@components/Toast';
 * toast.success("success message");
 * toast.error("error message");
 * toast.subtleError("subtle error message");
 */

import { COLORS, FONTS } from "@constants";
import { ReactElement } from "react";
import { ToastContainer, toast as toastify, ToastPosition } from "react-toastify";
import styled from "styled-components";

// Default toast container for standard notifications (top-center)
export const BannerToaster = styled(ToastContainer).attrs(() => ({
  enableMultiContainer: true,
  containerId: "banner",
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  closeButton: false,
  rtl: false,
  pauseOnFocusLoss: false,
  theme: "colored",
  draggable: true,
  pauseOnHover: true,
}))`
  && {
    &.Toastify__toast-container {
      z-index: 1005;
      width: 658px;
      text-align: center;
      transform: translateX(-50%);
    }
    .Toastify__toast {
      border-radius: 4px;
    }
    .Toastify__toast-body {
      ${FONTS.MEDIUM_1}
      color: ${COLORS.WHITE};
      margin: 0 auto;
      flex: none;
      max-width: 100%;
      a {
        color: ${COLORS.WHITE};
        text-decoration: underline;
      }
    }
    .Toastify__toast--success {
      background-color: ${COLORS.GREEN};
      border-color: ${COLORS.GREEN};
    }
    .Toastify__toast--error {
      background-color: ${COLORS.RED};
      border-color: ${COLORS.RED};
    }
  }
`;

// Subtle slide-in toast container (bottom-right)
export const SubtleToaster = styled(ToastContainer).attrs(() => ({
  enableMultiContainer: true,
  containerId: "subtle",
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  closeButton: false,
  rtl: false,
  pauseOnFocusLoss: false,
  theme: "light",
  draggable: true,
  pauseOnHover: true,
}))`
  && {
    &.Toastify__toast-container {
      z-index: 100;
      width: 384px;
      padding: 16px;
      transform: none;
      overflow: visible;
    }
    .Toastify__toast {
      border-radius: 8px;
      margin-bottom: 8px;
      padding: 12px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      background: ${COLORS.WHITE};
      border: 1px solid ${COLORS.NEUTRAL_200};
      min-height: unset;
      max-height: none;
      height: auto;
      overflow: visible;
      display: flex;
      flex-direction: row;
    }
    .Toastify__toast-body {
      ${FONTS.MEDIUM_1}
      color: ${COLORS.NEUTRAL_800};
      padding: 4px;
      margin: 0;
      flex: 1;
      font-size: 14px;
      line-height: 20px;
      white-space: normal;
      overflow: visible;
      word-wrap: break-word;
      word-break: break-word;
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;

      > div:first-child {
        display: flex;
        align-items: center;
        margin-right: 12px;
        padding: 2px;
      }

      > div:last-child {
        flex: 1;
      }

      a {
        color: ${COLORS.HALO_BLUE};
        text-decoration: underline;
      }
    }
    .Toastify__toast--error {
      border-left: 4px solid ${COLORS.RED};
      background: ${COLORS.WHITE};
    }
  }
`;

export const ToastContainers = () => (
  <>
    <BannerToaster />
    <SubtleToaster />
  </>
);

export const Toaster = ToastContainers;

// Aliases for backward compatibility
export const SuccessToaster = BannerToaster;
export const ErrorToaster = BannerToaster;
export const AdminToaster = SubtleToaster;

const toast = {
  success: (message: string | ReactElement) => {
    toastify.success(message, { 
      containerId: "banner",
      position: "top-center"
    });
  },
  error: (message: string | ReactElement) => {
    toastify.error(message, { 
      containerId: "banner",
      position: "top-center"
    });
  },
  subtleError: (message: string | ReactElement, position: ToastPosition = "bottom-right") => {
    toastify.error(message, { 
      containerId: "subtle",
      position
    });
  },
  // For backward compatibility
  adminError: (message: string | ReactElement) => {
    return toast.subtleError(message);
  }
};

// Make toast functions globally available
declare global {
  interface Window {
    toast: typeof toast;
  }
}
window.toast = toast;

export default toast;
