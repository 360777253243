import { Icon } from "@components/library";
import { COLORS } from "@constants";
import { Size } from "@tsTypes/index";
import { useState } from "react";
import styled from "styled-components";

interface Props {
  imageUrl?: string | null;
  isPrivate?: boolean;
  size?: Extract<Size, "xs" | "sm" | "md" | "lg">;
}

const CompanyAvatar = ({ imageUrl, isPrivate = false, size = "lg" }: Props) => {
  const [hasError, setHasError] = useState(false);

  return (
    <Container size={size}>
      {(() => {
        if (isPrivate) return <Icon name="Private Company" size={size === "lg" ? "lg" : "md"} />;
        if (imageUrl && !hasError)
          return <Avatar src={imageUrl} alt="" size={size} onError={() => setHasError(true)} />;
        return <Icon name="Organization" size={size} color={COLORS.NEUTRAL_400} />;
      })()}
    </Container>
  );
};

export default CompanyAvatar;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLORS.NEUTRAL_250};
  background-color: ${COLORS.WHITE};
  padding: 0 8px;
  ${({ size }) => {
    if (size === "xs") {
      return `
        width: 24px;
        height: 24px;
        border-radius: 4px;
      `;
    } else if (size === "sm") {
      return `
        width: 40px;
        height: 40px;
        border-radius: 4px;
      `;
    } else if (size === "md") {
      return `
        width: 48px;
        height: 48px;
        border-radius: 12px;
      `;
    }
    return `
      width: 64px;
      height: 64px;
      border-radius: 12px;
    `;
  }}
`;
const Avatar = styled.img`
  ${({ size }) => {
    if (size === "xs") {
      return `
        max-width: 18px;
        max-height: 18px;
      `;
    } else if (size === "sm") {
      return `
        max-width: 34px;
        max-height: 34px;
      `;
    } else if (size === "md") {
      return `
        max-width: 36px;
        max-height: 36px;
      `;
    }
    return `
      max-width: 48px;
      max-height: 48px;
    `;
  }}
`;
