import { Name } from "@tsTypes/__generated__/enums/requests/feedback_deadline_reminder/name";
import { Stage } from "@tsTypes/__generated__/enums/requests/feedback_deadline_reminder/stage";
import { RequestForExpertsShowResponse } from "./request_for_experts/show";
import { RequestForStartupsShowResponse } from "./request_for_startups/show";
import { RfpPageRequest } from "./rfps/rfp_page";

// NOTE: Also update config/initializers/request_custom_data_data_type.rb
export interface RequestCustomData {
  is_from_pepsico_procurement: boolean;
}

export enum RequestableType {
  OPEN_CALL = "OpenCall",
  RFP = "Rfp",
  REQUEST_FOR_STARTUP = "RequestForStartup",
  REQUEST_FOR_EXPERT = "RequestForExpert",
}

export enum LaunchableRequestStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  COMING_SOON = "COMING_SOON",
  IN_EXTENSION = "IN_EXTENSION",
}

export enum RequestFeedbackDeadlineStatus {
  NOT_APPLICABLE = "NOT_APPLICABLE",
  NEEDS_DEADLINE = "NEEDS_DEADLINE",
  BEFORE_DEADLINE = "BEFORE_DEADLINE",
  DEADLINE_APPROACHING = "DEADLINE_APPROACHING",
  PAST_DEADLINE = "PAST_DEADLINE",
}

export enum RequestPartnerTypes {
  ACADEMIC_RESEARCHERS = "ACADEMIC_RESEARCHERS",
  STARTUPS = "STARTUPS",
  SUPPLIERS = "SUPPLIERS",
}

export enum RequestProposalSortBy {
  SUBMISSION_DATE = "submission_date",
  NAME = "name",
  TRL = "trl",
  SCORE = "score",
}

export type RequestProposalSortDirection = "asc" | "desc";

// Keep in sync with app/structs/requests/feedback_deadline_reminder.rb
export type FeedbackDeadlineReminder = {
  stage: Stage;
  name: Name;
  title: string;
  text: string;
  deadline_date?: string;
};

export type RequestUnion =
  | RfpPageRequest
  | RequestForExpertsShowResponse
  | RequestForStartupsShowResponse;
