import { Icon } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { Size } from "@tsTypes/ui";
import { ReactNode } from "react";
import styled from "styled-components";
import { IconName } from "../Icon/Icon";

type InfoBannerType = "info" | "attention-dark" | "attention-orange" | "ghost" | "magic";

interface Props {
  shouldDisplay?: boolean;
  text: ReactNode;
  font?: string;
  type?: InfoBannerType;
  width?: string;
  margin?: string;
  padding?: string;
  hideIcon?: boolean;
  "data-testid"?: string;
}

const InfoBanner = ({
  shouldDisplay = true,
  text,
  font,
  type = "info",
  width = "fit-content",
  margin,
  padding = "16px",
  hideIcon = false,
  "data-testid": dataTestId,
}: Props) => {
  const ICON_PROPS: Record<InfoBannerType, { name: IconName; color: string; size?: Size }> = {
    info: {
      name: "Info Small",
      color: COLORS.BLACK,
      size: "sm",
    },
    "attention-dark": {
      name: "Attention",
      color: COLORS.BLACK,
    },
    "attention-orange": {
      name: "Attention",
      color: COLORS.ORANGE_900,
    },
    ghost: {
      name: "Attention",
      color: COLORS.BLACK,
    },
    magic: {
      name: "Magic Full",
      color: COLORS.PINK_800,
    },
  };

  // In order to align the icon vertically with the text, accounting for different text sizes and
  // number of text rows, we need to adjust the height of the icon container
  let iconContainerHeight = 24; // px
  // When type is "info", the icon is actually a bit smaller but so is the default font
  if ((!font && type === "info") || font?.includes("font-size: 12px;")) {
    iconContainerHeight = 20; // px
  }

  return shouldDisplay ? (
    <Container
      width={width}
      font={font}
      margin={margin}
      padding={padding}
      type={type}
      data-testid={dataTestId}
    >
      {!hideIcon && (
        <IconContainer height={iconContainerHeight} isMagic={type === "magic"}>
          <Icon
            name={ICON_PROPS[type].name}
            color={ICON_PROPS[type].color}
            size={ICON_PROPS[type].size}
          />
        </IconContainer>
      )}
      {text}
    </Container>
  ) : null;
};

export default InfoBanner;

const Container = styled.div`
  display: flex;
  width: ${({ width }) => width};
  ${({ margin }) => margin && `margin: ${margin}`};
  padding: ${({ padding }) => padding};
  border-radius: 6px;
  ${({ type }) => {
    switch (type) {
      case "info":
        return `
          color: ${COLORS.BLACK};
          background-color: ${COLORS.NEUTRAL_100};
          ${FONTS.REGULAR_3}
        `;
      case "attention-orange":
        return `
          color: ${COLORS.ORANGE_900};
          background-color: ${COLORS.ORANGE_100};
          ${FONTS.MEDIUM_2}
        `;
      case "attention-dark":
        return `
          color: ${COLORS.BLACK};
          background-color: ${COLORS.NEUTRAL_100};
          ${FONTS.MEDIUM_2}
        `;
      case "ghost":
        return `
          color: ${COLORS.BLACK};
          background-color: ${COLORS.WHITE};
          ${FONTS.MEDIUM_2}
        `;
      case "magic": {
        return `
          color: ${COLORS.PINK_800};
          background-color: ${COLORS.PINK_50};
          ${FONTS.REGULAR_2}
        `;
      }
    }
  }}
  ${({ font }) => font && font};
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: ${({ height }) => height}px;
  margin: ${({ isMagic }) => `0 ${isMagic ? 10 : 6}px 0 ${isMagic ? 0 : -4}px`};
`;
