export enum RfpPartnershipModel {
  SPONSORED_RESEARCH = "SPONSORED_RESEARCH",
  GIFT = "GIFT",
  CO_DEVELOPMENT = "CO_DEVELOPMENT",
  SUPPLY_PURCHASE = "SUPPLY_PURCHASE",
  LICENSING = "LICENSING",
  MATERIAL_TRANSFER = "MATERIAL_TRANSFER",
  CAPSTONE_PROJECT = "CAPSTONE_PROJECT",
  CONSULTING_PROJECT = "CONSULTING_PROJECT",
}

export const PARTNERSHIP_MODEL_LABELS = {
  [RfpPartnershipModel.SPONSORED_RESEARCH]: "Sponsored research",
  [RfpPartnershipModel.GIFT]: "Gift (Unrestricted grant)",
  [RfpPartnershipModel.CO_DEVELOPMENT]: "Co-development",
  [RfpPartnershipModel.SUPPLY_PURCHASE]: "Supply/purchase",
  [RfpPartnershipModel.LICENSING]: "Licensing",
  [RfpPartnershipModel.MATERIAL_TRANSFER]: "Material transfer",
  [RfpPartnershipModel.CAPSTONE_PROJECT]: "Capstone project",
  [RfpPartnershipModel.CONSULTING_PROJECT]: "Consulting project",
};

export enum RfpBenefitType {
  // Funding benefits
  SPONSORED_RESEARCH = "sponsored_research", // Only for RFP
  GIFT = "gift", // Only for RFP and RFE
  INVESTMENT = "investment", // Only for RFS
  PAYMENT = "payment", // Only for RFE
  // Non-monetary benefits
  EXPERTISE = "expertise",
  TOOLS_AND_TECHNOLOGY = "tools_and_technology",
  COMPOUNDS_AND_REAGENTS = "compounds_and_reagents",
  FACILITIES_AND_SERVICES = "facilities_and_services",
  DATA = "data",
  NETWORKING = "networking",
  EARLY_ACCESS = "early_access", // Only for RFE
  MARKET_ACCESS = "market_access", // Only for RFP and RFS
  // Deprecated- we want to still display existing benefits, but we will not be adding new ones of these types
  SERVICES_AGREEMENT = "services_agreement",
  EXPOSURE = "exposure",
}

export enum RfpStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  COMING_SOON = "COMING_SOON",
  IN_EXTENSION = "IN_EXTENSION",
}
